import React, { useState, useEffect } from 'react';
import { Navigation } from './components/navigation';
import JsonData from './data/data.json';
import SmoothScroll from 'smooth-scroll';
import './App.css';
import WaitlistForm1 from './components/waitlist1';
import WaitlistForm2 from './components/waitlist2';
import WaitlistForm3 from './components/waitlist3';
import PrivacyPolicy from './components/privacy';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import TermsOfService from './components/termsofservice';
// Importing newly created components
// import SecondComponent from './SecondComponent';
// import FirstComponent from './FirstComponent';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function App() {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const timestamp = Date.now();
  const randomComponentIndex = timestamp % 3; // Remainder of division by 3 will be 0, 1, or 2
  let RandWaitList;

  if (randomComponentIndex === 0) {
    RandWaitList = <WaitlistForm1 />;
  } else if (randomComponentIndex === 1) {
    RandWaitList = <WaitlistForm2 />;
  } else {
    RandWaitList = <WaitlistForm3 />;
  }

  RandWaitList = <WaitlistForm3 />;

  return (
    // BrowserRouter to wrap all
    // the other components
    <BrowserRouter>
      {/*switch used to render only the first
    route that matches the location rather
    than rendering all matching routes. */}
      <Routes>
        <Route
          exact
          path='/'
          element={
            <div>
              {/* <Navigation data={landingPageData.Navigation} /> */}
              <div className='bg-container'>
                <div className='blur'></div>
              </div>
              <div className='header-cont'>
                <div className='header-logo text-center'>
                  <img alt='logo' height='120' src='assets/icon/logo_only.png' />
                </div>
                {/* <div className='header-text text-center'>JostlApp</div> */}
              </div>

              {RandWaitList}
              {/* <PrivacyPolicy /> */}
            </div>
          }
        ></Route>
        <Route exact path='/privacy' element={<PrivacyPolicy />}></Route>
        <Route exact path='/terms-of-service' element={<TermsOfService />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;
