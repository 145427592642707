import React from 'react';

const WaitlistForm1 = () => {
  return (
    <div id='waitlist1' className='waitlist vertical-align'>
      <div className='overlay'>
        <div className='container text-center'>
          <div className='section-title text-center'></div>
          <div className='row text-center'>
            <div className='col-12'>
              <p className='lead'>JostlApp, connect with a touch.</p>
            </div>
          </div>
          <div className='row text-center'>
            <div className='col-sm-12 col-md-4'></div>
            <div className='col-sm-12 col-md-4'>
              {/* <div className='ml-embedded' data-form='HECTI3'></div> */}
              <div class='ml-embedded' data-form='jrryZ3'></div>
              <span>
                &nbsp; <i className='fa fa-video-camera'></i>
              </span>
            </div>

            <div className='col-sm-12 col-md-4'></div>
          </div>
          <h1> </h1>
        </div>
      </div>
    </div>
  );
};

export default WaitlistForm1;
