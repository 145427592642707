import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
// import ReactDom from 'react-dom';
// import mdFromPath from './assets/privacy-policy.md';

const PrivacyPolicy = () => {
  const mdFromPath = './assets/privacy-policy.md';
  let [content, setContent] = useState({ md: '' });

  useEffect(() => {
    fetch(mdFromPath)
      .then((res) => res.text())
      .then((md) => {
        setContent({ md });
      });
  }, []);

  return (
    <div className='policy-container'>
      <ReactMarkdown children={content.md}></ReactMarkdown>
    </div>
  );
};

export default PrivacyPolicy;
