import React from 'react';

const WaitlistForm2 = () => {
  return (
    <div id='waitlist2' className='waitlist vertical-align'>
      <div className='overlay'>
        <div className='container text-center'>
          <div className='section-title text-center'></div>
          <div className='row text-center'>
            <div className='col-12'>
              <p className='lead'>JostlApp, connect with a touch.</p>
            </div>
          </div>
          <div className='row text-center'>
            <div className='col-sm-12 col-md-4'></div>
            <div className='col-sm-12 col-md-4'>
              {/* <div className='ml-embedded' data-form='OywM0m'></div> */}{' '}
              <div class='ml-embedded' data-form='jrryZ3'></div>
              <div className='video-link-cont'>
                <a href='./assets/video/waitlist_vid_2.mp4' target='_blank'>
                  Welcome video message
                  <span>
                    &nbsp; <i className='fa fa-video-camera'></i>
                  </span>
                </a>
              </div>
            </div>
            <div className='col-sm-12 col-md-4'></div>
          </div>
          <h1> </h1>
        </div>
      </div>
    </div>
  );
};

export default WaitlistForm2;
