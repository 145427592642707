import React from 'react';

const WaitlistForm3 = () => {
  return (
    <div id='waitlist3' className='waitlist flex'>
      <div className='overlay'>
        <div className='container text-center'>
          <div className='section-title text-center'></div>
          <div className='row text-center'>
            <div className='col-12'>
              {/* <p className='lead'>JostlApp, connect with a touch.</p> */}
            </div>
          </div>
          <div className='row text-center'>
            {/* <div className='col-sm-12 col-md-1'></div> */}
            <div className='col-sm-12 col-md-12'>
              <div className='waitlist-title color-primary text-center'>
                <div className='header-text text-center'>JostlApp</div>{' '}
                <span>Cool, smooth and convenient way to make a new contact</span>
              </div>
              {/* <div className='ml-embedded' data-form='GxoJlu'></div> */}
              {/* <div className='ml-embedded' data-form='fev1ee'></div> */}

              {/* <div className='ml-embedded' data-form='RtHuSk'></div> */}
              <div class='ml-embedded' data-form='jrryZ3'></div>

              {/* <video controls>
                <source src='/assets/video/waitlist_vid_2.mp4' type='video/mp4' />
                Your browser does not support the video tag.
              </video> */}
              <div className='video-link-cont'>
                {/* <ReactPlayer url='/assets/video/waitlist_vid_2.mp4' /> */}

                <a
                  className='color-primary'
                  href='./assets/video/waitlist_vid_2.mp4'
                  target='_blank'
                >
                  Watch video
                  <span>
                    &nbsp; <i className='fa fa-video-camera'></i>
                  </span>
                </a>
              </div>
              <div className='footer privacy'>
                <a className='color-primary' href='/privacy' target='_blank'>
                  Privacy Policy |
                </a>
                <a className='color-primary' href='/terms-of-service' target='_blank'>
                  Terms of Service |
                </a>
                <a href='/'>v1.0.0</a>
              </div>
            </div>
            {/* <div className='col-sm-12 col-md-1'></div> */}
          </div>
          <h1> </h1>
        </div>
      </div>
    </div>
  );
};

export default WaitlistForm3;
